import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />

    <section class="hero">
      <div class="hero-body">
        <div class="container has-text-centered">
          <h1 class="title">
            <Link to="/">
              <img
                src={require("../images/logo-tectonic-landscapes-large.svg")}
                alt="Tectonic landscapes"
                className="tectonic-logo"
              />
            </Link>
          </h1>
          <h1 class="title">404 - Page not found</h1>
          <h2 class="subtitle">
            Sorry, the page you are looking for could not be found.
          </h2>
        </div>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;
